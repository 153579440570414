<template>
  <div>
    <van-nav-bar
      title="修改支付密码"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-form @submit="onSubmit">
      <van-field
        v-model="password"
        type="password"
        maxlength="6"
        name="password"
        label="旧密码"
        placeholder="请输入旧支付密码"
        readonly
        clickable
        @click="clickField(1)"
      />
      <van-field
        v-model="newPassword"
        type="password"
        name="newPassword"
        maxlength="6"
        label="新密码"
        placeholder="请输入新支付密码"
        readonly
        clickable
        @click="clickField(2)"
      />
      <van-field
        v-model="rePassword"
        type="password"
        name="rePassword"
        maxlength="6"
        label="确认密码"
        placeholder="请再次输入新支付密码"
        readonly
        clickable
        @click="clickField(3)"
      />
      <div class="margin-20">
        <van-button round block type="danger" native-type="submit">提交</van-button>
      </div>
    </van-form>
    <van-number-keyboard
      v-model="password"
      :show="show1"
      random-key-order
      :maxlength="6"
      @blur="show1 = false"
    />
    <van-number-keyboard
      v-model="newPassword"
      :show="show2"
      random-key-order
      :maxlength="6"
      @blur="show2 = false"
    />
    <van-number-keyboard
      v-model="rePassword"
      :show="show3"
      random-key-order
      :maxlength="6"
      @blur="show3 = false"
    />
  </div>
</template>

<script>
import { setPayPwd } from '@/utils/api.js'
export default {
  name: 'setPayPassword',
  data () {
    return {
      value: '',
      show1: false,
      show2: false,
      show3: false,
      password: '',
      newPassword: '',
      rePassword: ''
    }
  },
  methods: {
    clickField(type) {
      if(type === 1) {
        this.show1 = true
        this.password = ''
      }
      if(type === 2) {
        this.show2 = true
        this.newPassword = ''
      }
      if(type === 3) {
        this.show3 = true
        this.rePassword = ''
      }
    },
    onSubmit () {
      if (this.password === '') {
        this.$toast('请输入旧密码')
        return
      }
      if (this.newPassword === '') {
        this.$toast('请输入新密码')
        return
      }
      if (this.rePassword === '') {
        this.$toast('请重复输入新密码')
        return
      }
      if (this.newPassword !== this.rePassword) {
        this.$toast('两次密码输入不一致')
        return
      }
      const param = { payPassword: this.password, newPassword: this.newPassword }
      setPayPwd(param).then(res => {
        if (res.code === 200) {
          this.$toast.success({
            message: res.message,
            duration: 1500,
            onClose: () => {
              this.onClickLeft()
            }
          })
        } else {
          this.$toast(res.message)
        }
      })
    },
    onClickLeft () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>
